@font-face {
  font-family: 'Apercu';
  src: url(./assets/fonts/Apercu/Apercu.otf) format('opentype');
}

@font-face {
  font-family: 'ApercuBold';
  src: url(./assets/fonts/Apercu/ApercuBold.otf) format('opentype');
}

@font-face {
  font-family: 'ApercuLight';
  src: url(./assets/fonts/Apercu/ApercuLight.otf) format('opentype');
}

@font-face {
  font-family: 'ApercuMedium';
  src: url(./assets/fonts/Apercu/ApercuMedium.otf) format('opentype');
}

@font-face {
  font-family: 'ApercuMono';
  src: url(./assets/fonts/Apercu/ApercuMono.otf) format('opentype');
}
